<script>
    import Form from '../Form.vue';
    import ModalManager from '../../services/modalManager';

    export default {
        extends: Form,

        props: {
            url: {
                type: String,
                required: true,
            },
        },

        mounted() {
            ModalManager.$on('modalClosed', ({ parent_id: parentId }) => {
                // Change the overview and table url to make sure the data fetched is of the correct external interface
                const url = this.$refs.zorgMailOverview.config.url;

                this.$refs.zorgMailOverview.setUrl(url.replace('-1', parentId));
            });
        },

        methods: {
            navigateToInstitute(instituteId) {
                window.location.assign(this.url.replace(':id', instituteId));
            },
        },
    };
</script>
