<script>
    import i18n from '../i18n';
    import DialogManager from '../services/DialogManager';
    import closest from '../services/closest';

    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            autosubmit: {
                type: Boolean,
                default: false,
            },
            preventUnload: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            const data = {
                formData: {},
                formCancelled: false,
                formSubmitted: false,
                redirectTo: '',
                tabsLastChange: null,
            };

            if (window.frm && window.frm.forms && window.frm.forms[this.name] && window.frm.forms[this.name].data) {
                data.formData = window.frm.forms[this.name].data;
            }

            data.originalFormData = JSON.stringify(data.formData);

            return data;
        },

        computed: {
            formDataChanged() {
                return JSON.stringify(this.formData) !== this.originalFormData;
            },

            shouldPreventUnload() {
                return this.preventUnload && window.frm.state.confirmFormUnload && this.formDataChanged && !(this.formCancelled || this.formSubmitted);
            },

            submitButtonEnabled() {
                return !this.$store.state.core.requestPoolPending && !this.formSubmitted;
            },

            submitButtonLoading() {
                return this.formSubmitted;
            },

            nextButtonEnabled() {
                if (!this.tabsLastChange) {
                    return this.submitButtonEnabled;
                }

                return this.$refs.tabs.hasNext();
            },

            previousButtonEnabled() {
                if (!this.tabsLastChange) {
                    return this.submitButtonEnabled;
                }

                return this.$refs.tabs.hasPrevious();
            },
        },

        mounted() {
            const firstAutofocusElement = this.$el.querySelector('[autofocus]');

            if (firstAutofocusElement) {
                firstAutofocusElement.focus();
            }

            // Don't use the build-in validate, because that skips the onSubmit-event of the form
            this.$refs.form.setAttribute('novalidate', 'true');

            if (this.$refs.tabs) {
                // N.B. We use tabsLastChange to make some methods reactive.
                this.tabsLastChange = (new Date()).getTime();

                this.$refs.tabs.$on('change', () => {
                    this.tabsLastChange = (new Date()).getTime();
                });
            }

            window.addEventListener('beforeunload', event => {
                if (this.shouldPreventUnload) {
                    // Cancel the event to show confirmation prompt
                    event.preventDefault();
                    event.returnValue = '';
                }
            });

            window.addEventListener('pageshow', () => {
                this.formSubmitted = false;
            });

            if (this.autosubmit) {
                this.submit();
            }
        },

        methods: {
            submit(event) {
                if (event && this.shouldValidateForm(event) && this.formIsInvalid()) {
                    this.showFirstInvalidElement();
                    event.preventDefault();

                    return;
                }

                this.formSubmitted = true;

                if (this.afterSubmit) {
                    this.afterSubmit(event);
                }
            },

            cancel() {
                this.formCancelled = true;
            },

            submitAndRedirectToRoute(url) {
                if (this.formIsInvalid()) {
                    this.showFirstInvalidElement();

                    return;
                }

                DialogManager.confirm(i18n.tc('form.store-before-tab-switch'))
                    .then(() => {
                        this.redirectTo = url;

                        // wait for the actual DOM to update the <input> before submitting the form
                        this.formSubmitted = true;
                        this.$nextTick(() => {
                            this.$refs.form.submit();
                        });
                    }, () => {
                        // User canceled, all ok
                    });
            },

            formIsValid() {
                return this.$refs.form.checkValidity();
            },

            formIsInvalid() {
                return !this.formIsValid();
            },

            shouldValidateForm(event) {
                return !event.submitter?.attributes?.formnovalidate;
            },

            showFirstInvalidElement() {
                if (this.formIsValid()) {
                    return;
                }

                // Switch to the tab containing the first invalid input element
                const firstInvalidInputElement = this.$refs.form.querySelector('input:invalid,textarea:invalid,select:invalid');
                const panelElement = closest(firstInvalidInputElement, '.tabs__panel');
                if (panelElement) {
                    const currentLocation = window.location.toString();
                    const newLocation = currentLocation.split('#')[0] + '#' + panelElement.dataset.slug;

                    if (currentLocation !== newLocation) {
                        // We use a hashchange event to show nested tabs correctly without requiring a ref
                        window.history.pushState({}, null, newLocation);
                        window.dispatchEvent(new Event('hashchange'));
                    }
                }

                // This has to be in a setTimeout-0 because otherwise the reported validity bubbles are not visible anymore after a tab switch
                setTimeout(() => {
                    this.$refs.form.setAttribute('novalidate', 'false');

                    if (firstInvalidInputElement) {
                        firstInvalidInputElement.scrollIntoView({ block: 'center' });
                    }

                    // Try to show the native validity bubbles again
                    if (window.HTMLFormElement.prototype.reportValidity) {
                        this.$refs.form.reportValidity();
                    }

                    // Add the invalid class to the form inputs
                    Array.from(this.$refs.form.getElementsByTagName('input')).forEach(element => {
                        if (!element.checkValidity()) {
                            element.classList.add('form-input--with-error');
                        } else if (element.getAttribute('required') || element.getAttribute('pattern')) {
                            element.classList.remove('form-input--with-error');
                        }
                    });

                    Array.from(this.$refs.form.getElementsByTagName('textarea')).forEach(element => {
                        if (!element.checkValidity()) {
                            element.classList.add('form-textarea--with-error');
                        } else if (element.getAttribute('required')) {
                            element.classList.remove('form-textarea--with-error');
                        }
                    });

                    Array.from(this.$refs.form.getElementsByTagName('select')).forEach(element => {
                        if (!element.checkValidity()) {
                            element.classList.add('form-select__field--with-error');
                        } else if (element.getAttribute('required')) {
                            element.classList.remove('form-select__field--with-error');
                        }
                    });
                }, 0);
            },

            previousButtonClick() {
                if (this.$refs.tabs) {
                    this.$refs.tabs.selectPrevious();
                }
            },

            nextButtonClick() {
                if (this.$refs.tabs) {
                    this.$refs.tabs.selectNext();
                }
            },
        },
    };
</script>
