<template>
    <div>
        <frm-spinner v-if="insurerGroupsLoading" />

        <div v-else class="overview__table">
            <template v-for="(insurerGroup, index) in insurerGroups">
                <input :key="'id_'+index"
                       type="hidden"
                       :name="'insurer_groups['+index+'][id]'"
                       :value="insurerGroup.id"
                >
                <input :key="'selected_'+index"
                       type="hidden"
                       :name="'insurer_groups['+index+'][selected]'"
                       :value="selectedInsurerGroupIds.includes(insurerGroup.id) ? 1 : 0"
                >
                <template v-if="selectedInsurerGroupIds.includes(insurerGroup.id)">
                    <input :key="'no_contract_'+index"
                           type="hidden"
                           :name="'insurer_groups['+index+'][no_contract]'"
                           :value="noContractInsurerGroupIds.includes(insurerGroup.id) ? 1 : 0"
                    >
                    <input :key="'contract_'+index"
                           type="hidden"
                           :name="'insurer_groups['+index+'][contract_id]'"
                           :value="contractIds[index]"
                    >
                    <input :key="'employee_'+index"
                           type="hidden"
                           :name="'insurer_groups['+index+'][employee_id]'"
                           :value="employeeIds[index]"
                    >
                </template>
            </template>

            <table class="table">
                <thead>
                    <tr>
                        <th />
                        <th>
                            <span class="table__th-wrapper">{{ $t('masterfiles.fields.insurer-group') }}</span>
                        </th>
                        <th>
                            <span class="table__th-wrapper">{{ $t('masterfiles.fields.contract') }}</span>
                        </th>
                        <th>
                            <span class="table__th-wrapper">{{ $t('masterfiles.fields.employee') }}</span>
                        </th>
                        <th>
                            <span class="table__th-wrapper" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(insurerGroup, index) in insurerGroups" :key="insurerGroup.id" class="zebra">
                        <td class="overview__column  overview__column--checkbox">
                            <div v-if="selectableInsurerGroupsIds.includes(insurerGroup.id)"
                                 class="form-checkbox overview-checkbox"
                            >
                                <input :id="'enabled-'+insurerGroup.id"
                                       v-model="selectedInsurerGroupIds"
                                       type="checkbox"
                                       :value="insurerGroup.id"
                                       class="form-radio__input form-checkbox__input"
                                >
                                <label class="form-checkbox__label" :for="'enabled-'+insurerGroup.id" />
                            </div>
                        </td>
                        <td>
                            <label :for="'enabled-'+insurerGroup.id">
                                {{ insurerGroup.description }}
                            </label>
                        </td>
                        <td>
                            <frm-input-select
                                v-if="contractEditableInsurerGroupIds.includes(insurerGroup.id)"
                                :id="'contract-'+insurerGroup.id"
                                v-model="contractIds[index]"
                                :options="insurerGroup.contracts"
                                :required="selectedInsurerGroupIds.includes(insurerGroup.id)"
                                @input="select(insurerGroup.id)"
                            />
                            <template v-else-if="noContractInsurerGroupIds.includes(insurerGroup.id)" />
                            <template
                                v-else-if="insurerGroup.no_contract && !editExistingContractInsurerGroupIds.includes(insurerGroup.id)"
                            >
                                <frm-icon class="information-list__icon" name="status-warning" />
                                {{
                                    $t('masterfiles.year-transition.no-contract', {
                                        'insurer-group': insurerGroup.description,
                                        'year': year
                                    })
                                }}
                            </template>
                            <template
                                v-else-if="existingContractInsurerGroupIds.includes(insurerGroup.id) && !editExistingContractInsurerGroupIds.includes(insurerGroup.id)"
                            >
                                <frm-icon class="information-list__icon" name="status-achieved" />
                                {{
                                    $t('masterfiles.year-transition.contract-exists', {
                                        'insurer-group': insurerGroup.description,
                                        'contract': insurerGroup.last_contract_description,
                                        'year': year
                                    })
                                }}
                            </template>
                        </td>
                        <td>
                            <frm-input-select
                                v-if="contractEditableInsurerGroupIds.includes(insurerGroup.id)"
                                :id="'employee-'+insurerGroup.id"
                                v-model="employeeIds[index]"
                                :options="employees"
                                :required="selectedInsurerGroupIds.includes(insurerGroup.id)"
                                @input="select(insurerGroup.id)"
                            />
                        </td>
                        <td class="overview__column">
                            <div class="form-checkbox">
                                <template
                                    v-if="insurerGroup.no_contract || existingContractInsurerGroupIds.includes(insurerGroup.id)"
                                >
                                    <input :id="'edit-contract-'+insurerGroup.id"
                                           v-model="editExistingContractInsurerGroupIds"
                                           type="checkbox"
                                           :value="insurerGroup.id"
                                           class="form-radio__input form-checkbox__input"
                                           @input="select(insurerGroup.id)"
                                    >
                                    <label class="form-checkbox__label" :for="'edit-contract-'+insurerGroup.id">
                                        {{ $t('masterfiles.year-transition.edit-contract') }}
                                    </label>
                                </template>
                                <template v-else>
                                    <input :id="'no-contract-'+insurerGroup.id"
                                           v-model="noContractInsurerGroupIds"
                                           type="checkbox"
                                           :value="insurerGroup.id"
                                           class="form-radio__input form-checkbox__input"
                                           @input="select(insurerGroup.id)"
                                    >
                                    <label class="form-checkbox__label" :for="'no-contract-'+insurerGroup.id">
                                        {{ $t('masterfiles.fields.no-contract') }}
                                    </label>
                                </template>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    props: {
        year: {
            type: String,
            required: true,
        },
        instituteId: {
            type: String,
            required: true,
        },
        insurerGroupsRoute: {
            type: String,
            required: true,
        },
        employees: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            insurerGroupsLoading: true,
            submitButtonLoading: false,
            insurerGroups: [],
            contractIds: [],
            employeeIds: [],
            selectedInsurerGroupIds: [],
            noContractInsurerGroupIds: [],
            editExistingContractInsurerGroupIds: [],
        };
    },

    mounted() {
        this.fetchContracts();
    },

    watch: {
        year() {
            this.fetchContracts();
        },
        instituteId() {
            this.fetchContracts();
        },
        insurerGroups() {
            const allInsurerGroupsNull = this.insurerGroups.map(() => null);

            this.contractIds = [...allInsurerGroupsNull];
            this.employeeIds = [...allInsurerGroupsNull];
        },
    },

    computed: {
        insurerGroupsUrl() {
            return this.insurerGroupsRoute
                .replace(':year', this.year);
        },
        selectableInsurerGroupsIds() {
            return this.insurerGroups
                .filter(insurerGroup => {
                    if (insurerGroup.no_contract || this.existingContractInsurerGroupIds.includes(insurerGroup.id)) {
                        return this.editExistingContractInsurerGroupIds.includes(insurerGroup.id);
                    }

                    return true;
                })
                .map(insurerGroup => insurerGroup.id);
        },
        contractEditableInsurerGroupIds() {
            return this.insurerGroups
                .filter(insurerGroup => this.shouldShowContractEditFields(insurerGroup))
                .map(insurerGroup => insurerGroup.id);
        },
        existingContractInsurerGroupIds() {
            return this.insurerGroups
                .filter(insurerGroup => String(insurerGroup.last_contract_year) === this.year)
                .filter(insurerGroup => !insurerGroup.no_contract)
                .map(insurerGroup => insurerGroup.id);
        },
    },

    methods: {
        shouldShowContractEditFields(insurerGroup) {
            if (this.noContractInsurerGroupIds.includes(insurerGroup.id)) {
                return false;
            }

            if (insurerGroup.no_contract && !this.editExistingContractInsurerGroupIds.includes(insurerGroup.id)) {
                return false;
            }

            if (this.existingContractInsurerGroupIds.includes(insurerGroup.id) && !this.editExistingContractInsurerGroupIds.includes(insurerGroup.id)) {
                return false;
            }

            return true;
        },
        async fetchContracts() {
            this.insurerGroupsLoading = true;
            this.insurerGroups = (await axios.get(this.insurerGroupsUrl, {
                params: {
                    institute_id: this.instituteId,
                },
            })).data;
            this.insurerGroupsLoading = false;
        },
        select(insurerGroupId) {
            if (!this.selectedInsurerGroupIds.includes(insurerGroupId)) {
                this.selectedInsurerGroupIds.push(insurerGroupId);
            }
        },
    },
};
</script>
