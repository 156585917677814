<script>
    import ModalManager from '../../../services/modalManager';
    import Default from './Default.vue';
    import axios from 'axios';
    import merge from 'deepmerge';
    import HandlesInstitute from '../../../mixins/HandlesInstitute';

    export default {
        extends: Default,

        mixins: [HandlesInstitute],

        props: {
            preselectDataForIndication: {
                type: Object,
                required: true,
            },

            preselectDataForTreatmentPlan: {
                type: Object,
                required: true,
            },

            hypothesesRoute: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                hasHypotheses: false,
                hypothesesCount: 0,
                caregiverPermissionConclusion: false,
            };
        },

        mounted() {
            this.formData.institute_id = this.instituteId;
        },

        computed: {
            nextButtonEnabled() {
                return this.treatmentPlanTabEnabled;
            },

            /**
             * @return {Boolean}
             */
            showExtraFieldsForIndication() {
                if (this.isConsultation) {
                    return String(this.formData.anamnesis.consult.indication_physical_therapy_after_consult) === this.indicationYesAnswerId;
                }

                return String(this.formData.anamnesis.indication_physical_therapy) === this.indicationYesAnswerId;
            },
        },

        watch: {
            'formData.indication'(newValue, oldValue) {
                if (String(newValue) === String(oldValue)) {
                    return;
                }

                Object.assign(this.formData, merge(this.formData, this.preselectDataForIndication[newValue] || {}));
            },

            'formData.treatment_plan'(newValue, oldValue) {
                if (String(newValue) === String(oldValue)) {
                    return;
                }

                Object.assign(this.formData, merge(this.formData, this.preselectDataForTreatmentPlan[newValue] || {}));
            },
        },

        created() {
            this.checkHypotheses();
            this.fetchCaregiverPermission();
        },

        methods: {
            openHypothesisModal() {
                ModalManager.openModal('/fysioroadmap/' + this.formData.patient_id + '/' + this.indicationHistoryId + '/hypothesis')
                    .then(() => {
                        this.checkHypotheses();
                    });
            },

            checkHypotheses() {
                if (this.formData.module === 'childrens_physical_therapy') {
                    axios.get(this.hypothesesRoute).then(response => {
                        this.hypothesesCount = response.data.data.length;
                        if (this.hypothesesCount > 0) {
                            this.hasHypotheses = true;
                        }
                    });
                }
            },
        },
    };
</script>
